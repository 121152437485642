<template>
  <div class="page-wrapper">
    <div class="page-body">
      <div class="container-xl">
        <MoleculeFileManager
          link="https://disk.yandex.ru/d/cogLL0NyrTGc9g"
          :link-title="$t('file.manager.to-google-drive')"
          :title="$t('pages.filemanager.title')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MoleculeFileManager from '@/components/MoleculeFileManager'

export default {
  title: vm => vm.$t('pages.filemanager.title'),

  components: {
    MoleculeFileManager,
  },
}
</script>
