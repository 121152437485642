<template>
  <MoleculeCard class="card-form">
    <template #header>
      <AtomButton
        @click="back"
        class="me-3"
        :title="$t('action.back')"
      >
        <arrow-left-icon />
      </AtomButton>
      <h2 class="card-title fs-h2">{{ activeTitle }}</h2>
      <div class="card-actions">
        <AtomButton
          href="https://drive.google.com/drive/folders/1vWkXDOLdOtMV9wWfm6eSjkEMzwVvgrp9?usp=sharing"
          target="_blank"
          class="d-none d-sm-inline-block"
        >
          <external-link-icon />{{ linkTitle || $t('action.open') }}
        </AtomButton>
        <AtomButton
          v-if="link"
          :href="link"
          target="_blank"
          class="d-sm-none"
        >
          <external-link-icon />
        </AtomButton>
      </div>
    </template>
    <template #content>
      <ul
        v-if="loading"
        class="list-group list-group-flush placeholder-glow"
      >
        <li
          v-for="n in 9"
          :key="n"
          class="list-group-item"
        >
          <div class="row align-items-center">
            <div class="col-auto">
              <div class="avatar placeholder"></div>
            </div>
            <div class="col-6 col-lg-7">
              <div class="placeholder placeholder-xs col-7"></div>
              <div class="placeholder placeholder-xs col-9"></div>
            </div>
            <div class="col-1 ms-auto">
              <div class="placeholder placeholder-xs col-12"></div>
            </div>
            <div class="col-1 ms-auto">
              <div class="placeholder placeholder-xs col-12"></div>
            </div>
            <div class="col-2 col-lg-1 ms-auto text-end">
              <span
                class="btn btn-primary disabled placeholder w-100"
                aria-hidden="true"
              />
            </div>
          </div>
        </li>
      </ul>
      <div
        v-else-if="files.length"
        class="list-group list-group-flush list-group-hoverable"
      >
        <div
          v-for="item in files"
          :key="item.resource_id"
          class="list-group-item user-select-none"
          @dblclick="item.type === 'dir' ? open(item) : false"
          @click="mobileClick(item)"
          :title="item.type === 'dir' ? $t('file.manager.double-click') : false"
        >
          <div class="row align-items-center">
            <div class="col-auto">
              <AtomAvatar
                :color="{
                  'yellow-lt': item.type === 'dir',
                  'blue-lt': item.type === 'file',
                }">
                <component :is="(item.type === 'dir' ? 'folder' : 'file') + '-icon'" />
              </AtomAvatar>
            </div>
            <div class="col">
              <div class="item">
                <div class="item__title fs-h3 text-truncate mt-n1">{{ item.name }}</div>
                <div class="item__description">
                  <div class="text-muted text-center">
                    <template v-if="item.created">
                      {{ item.created | date('date') }}
                    </template>
                  </div>
                  <div class="text-center text-muted">
                    <template v-if="item.type === 'file' && item.size">
                      {{ item.size | formatBytes }}
                    </template>
                  </div>
                  <div class="list-group-item-actions">
                    <template v-if="item.type === 'file' && item.file">
                      <AtomButton
                        class="d-none d-md-inline-block"
                        color="primary"
                        :title="$t('action.download')"
                        @click.prevent="copyFileLink(item)"
                        @dblclick.stop
                      >
                        <copy-icon /> {{ $t('copy.file-link.label') }}
                      </AtomButton>
                      <AtomButton
                        class="d-md-none"
                        color="primary"
                        size="sm"
                        :title="$t('action.download')"
                        @click.prevent="copyFileLink(item)"
                        @dblclick.stop
                      >
                        {{ $t('copy.file-link.label') }}
                      </AtomButton>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MoleculeEmpty
        v-else
        icon="cloud-off"
        :message="$t('file.manager.error.empty')"
      />
    </template>
  </MoleculeCard>
</template>

<script>
import MoleculeCard from '@/components/MoleculeCard'
import MoleculeEmpty from '@/components/MoleculeEmpty'
import AtomButton from '@/components/AtomButton'
import AtomAvatar from '@/components/AtomAvatar'

import { isTouchDevice } from '@/helpers'
import { functions } from '@/firebase'
import { mapMutations } from 'vuex'

export default {
  data () {
    return {
      loading: true,
      files: [],
      activeTitle: this.title,
      activePath: this.path,
    }
  },

  components: {
    MoleculeCard,
    MoleculeEmpty,
    AtomButton,
    AtomAvatar,
  },

  props: {
    link: {
      type: String,
      required: true,
    },

    linkTitle: {
      type: String,
    },

    path: {
      type: String,
      default: '/',
    },

    title: {
      type: String,
    },
  },

  mounted () {
    this.load(this.$route.params.path)
  },

  methods: {
    ...mapMutations(['setError']),

    async open (item) {
      if (item.type === 'dir') {
        this.load(item.path)
      }
    },

    back () {
      if (this.activePath.length > 1) {
        const path = this.activePath.split('/')
        path.pop()
        this.load(path.join('/'))
      } else {
        this.$router.push('/projects')
      }
    },

    async load (path) {
      this.loading = true
      try {
        const getProjectFiles = functions.httpsCallable('project-getFiles')
        const response = await getProjectFiles({
          link: this.link,
          path: path || this.path,
        })
        this.files = response.data._embedded.items
        if (path) {
          const names = path.split('/')
          this.activeTitle = names[names.length - 1]
        } else {
          this.activeTitle = this.title
        }
        this.activePath = path || this.path
      } catch (e) {
        this.setError(e)
      } finally {
        this.loading = false
      }
    },

    mobileClick (item) {
      if (isTouchDevice()) {
        this.open(item)
      }
    },

    async copyFileLink (item) {
      try {
        await navigator.clipboard.writeText(item.file)
        this.$notify({
          title: this.$t('action.success'),
          text: this.$t('copy.file-link.success'),
          type: 'success',
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .item {
    display: flex;
    flex-direction: column;

    &__description {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(3, max-content);
      align-items: center;

      > * {
        white-space: nowrap;
      }
    }

    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &__description {
        grid-template-columns: repeat(2, 120px) 200px;
      }
    }
  }
</style>
